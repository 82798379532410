<template>
  <div>
    <apexchart
      type="donut"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import Service from "../../services";
export default {
  props: {
    city: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      series: [0, 0, 0, 0, 0],
      data: [],
      chartOptions: {
        dataLabels: {
          enabled: true,
          textAnchor: "start"
        },

        title: {
          text: "Status dos pacientes confirmados",
          align: "left",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            color: "#263238"
          }
        },
        chart: {
          type: "donut",
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            }
          }
        },
        labels: [
          "Isolamento domiciliar",
          "Hospitalizados",
          "Curados",
          "Óbitos"
        ],
        theme: {
          palette: "palette6"
        },
        // "#F86624", "#999ccc"
        responsive: [
          {
            options: {
              chart: {
                padding: 10
              }
            }
          }
        ],
        legend: {
          show: true,
          position: "top"
        }
      }
    };
  },
  created() {
    this.populate();
  },
  methods: {
    populate() {
      const request = new Service();
      request.get(`statistics/cities/${this.city}`).then(res => {
        let aux = res;
        this.series = [
          Number(aux.c_qty_isolated),
          Number(aux.c_qty_interned),
          Number(aux.c_qty_recovered),
          Number(aux.c_qty_lethal)
        ];
      });
    }
  }
};
</script>
