<template>
  <div>
    <apexchart
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import Service from "../../services";
export default {
  props: {
    currentFilter: {
      type: String
    },
    city: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      series: [],
      data: [],
      chartOptions: {}
    };
  },
  created() {
    this.populate();
  },
  methods: {
    getArrayForTypeFilter() {
      let series = [];
      switch (this.currentFilter) {
        case "confirmed":
          series.push({ name: "Confirmado", data: [] });
          break;
        case "suspicious":
          series.push({ name: "Suspeiros", data: [] });
          break;
        case "discarded":
          series.push({ name: "Descartados", data: [] });
          break;
        case "recovered":
          series.push({ name: "Curados", data: [] });
          break;
        case "lethal":
          series.push({ name: "Óbitos", data: [] });
          break;

        default:
          series.push(
            {
              name: "Confirmado",
              data: []
            },
            { name: "Suspeitos", data: [] },
            { name: "Descartados", data: [] },
            { name: "Curados", data: [] },
            {
              name: "Óbitos",
              data: []
            }
          );
          break;
      }
      return series;
    },
    populate() {
      let colors = [];
      switch (this.currentFilter) {
        case "confirmed":
          colors = ["#EA3546"];
          break;
        case "suspicious":
          colors = ["#F86624"];
          break;
        case "discarded":
          colors = ["#999999"];
          break;
        case "recovered":
          colors = ["#08BB68"];
          break;
        case "lethal":
          colors = ["#000"];
          break;
        default:
          colors = ["#EA3546", "#F86624", "#999999", "#08BB68", "#000"];
          break;
      }
      const request = new Service();
      request.get(`statistics/age/${this.city}`).then(res => {
        let series = this.getArrayForTypeFilter();

        let response = this.getDataForTypeFilter(res);
        if (response == undefined)
          this.series = [
            {
              ...series[0],
              data: [0, 0, 0, 0, 0]
            }
          ];
        else {
          if (this.currentFilter == "all") {
            let conf1 = parseInt(response.confirmed?.interval_1) || 0;
            let leth1 = parseInt(response.lethal?.interval_1) || 0;
            let reco1 = parseInt(response.recovered?.interval_1) || 0;
            let sum1 = conf1 + leth1 + reco1;

            let conf2 = parseInt(response.confirmed?.interval_2) || 0;
            let leth2 = parseInt(response.lethal?.interval_2) || 0;
            let reco2 = parseInt(response.recovered?.interval_2) || 0;
            let sum2 = conf2 + leth2 + reco2;

            let conf3 = parseInt(response.confirmed?.interval_3) || 0;
            let leth3 = parseInt(response.lethal?.interval_3) || 0;
            let reco3 = parseInt(response.recovered?.interval_3) || 0;
            let sum3 = conf3 + leth3 + reco3;

            let conf4 = parseInt(response.confirmed?.interval_4) || 0;
            let leth4 = parseInt(response.lethal?.interval_4) || 0;
            let reco4 = parseInt(response.recovered?.interval_4) || 0;
            let sum4 = conf4 + leth4 + reco4;

            let conf5 = parseInt(response.confirmed?.interval_5) || 0;
            let leth5 = parseInt(response.lethal?.interval_5) || 0;
            let reco5 = parseInt(response.recovered?.interval_5) || 0;
            let sum5 = conf5 + leth5 + reco5;

            this.series = [
              {
                ...series[0],
                data: [sum1, sum2, sum3, sum4, sum5]
              },
              {
                ...series[1],
                data: [
                  response.suspicious?.interval_1 || 0,
                  response.suspicious?.interval_2 || 0,
                  response.suspicious?.interval_3 || 0,
                  response.suspicious?.interval_4 || 0,
                  response.suspicious?.interval_5 || 0
                ]
              },
              {
                ...series[2],
                data: [
                  response.discarded?.interval_1 || 0,
                  response.discarded?.interval_2 || 0,
                  response.discarded?.interval_3 || 0,
                  response.discarded?.interval_4 || 0,
                  response.discarded?.interval_5 || 0
                ]
              },
              {
                ...series[3],
                data: [
                  response.recovered?.interval_1 || 0,
                  response.recovered?.interval_2 || 0,
                  response.recovered?.interval_3 || 0,
                  response.recovered?.interval_4 || 0,
                  response.recovered?.interval_5 || 0
                ]
              },
              {
                ...series[4],
                data: [
                  response.lethal?.interval_1 || 0,
                  response.lethal?.interval_2 || 0,
                  response.lethal?.interval_3 || 0,
                  response.lethal?.interval_4 || 0,
                  response.lethal?.interval_5 || 0
                ]
              }
            ];
          } else {
            if (this.currentFilter == "confirmed") {
              let conf1 = parseInt(res.confirmed?.interval_1) || 0;
              let leth1 = parseInt(res.lethal?.interval_1) || 0;
              let reco1 = parseInt(res.recovered?.interval_1) || 0;
              let sum1 = conf1 + leth1 + reco1;

              let conf2 = parseInt(res.confirmed?.interval_2) || 0;
              let leth2 = parseInt(res.lethal?.interval_2) || 0;
              let reco2 = parseInt(res.recovered?.interval_2) || 0;
              let sum2 = conf2 + leth2 + reco2;

              let conf3 = parseInt(res.confirmed?.interval_3) || 0;
              let leth3 = parseInt(res.lethal?.interval_3) || 0;
              let reco3 = parseInt(res.recovered?.interval_3) || 0;
              let sum3 = conf3 + leth3 + reco3;

              let conf4 = parseInt(res.confirmed?.interval_4) || 0;
              let leth4 = parseInt(res.lethal?.interval_4) || 0;
              let reco4 = parseInt(res.recovered?.interval_4) || 0;
              let sum4 = conf4 + leth4 + reco4;

              let conf5 = parseInt(res.confirmed?.interval_5) || 0;
              let leth5 = parseInt(res.lethal?.interval_5) || 0;
              let reco5 = parseInt(res.recovered?.interval_5) || 0;
              let sum5 = conf5 + leth5 + reco5;
              this.series = [
                {
                  ...series[0],
                  data: [sum1, sum2, sum3, sum4, sum5]
                }
              ];
            } else {
              this.series = [
                {
                  ...series[0],
                  data: [
                    response.interval_1 || 0,
                    response.interval_2 || 0,
                    response.interval_3 || 0,
                    response.interval_4 || 0,
                    response.interval_5 || 0
                  ]
                }
              ];
            }
          }
        }
      });
      this.chartOptions = {
        title: {
          text: "Distribuição de casos por faixa etária",
          align: "left",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            color: "#263238"
          }
        },
        legend: {
          show: true,
          position: "top"
        },

        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        // theme: {
        //   palette: "palette6", // upto palette10
        // },
        chart: {
          stacked: true,
          type: "bar"
        },
        xaxis: {
          categories: [
            "0 à 9 Anos",
            "10 à 19 Anos",
            "20 à 49 Anos",
            "50 à 69 Anos",
            "70 anos ou mais"
          ]
        },
        colors: colors,
        responsive: [
          {
            options: {
              chart: {
                padding: 10
              }
            }
          }
        ]
      };
    },
    getDataForTypeFilter(response) {
      switch (this.currentFilter) {
        case "confirmed":
          return response.confirmed;
        case "suspicious":
          return response.suspicious;
        case "discarded":
          return response.discarded;
        case "recovered":
          return response.recovered;
        case "lethal":
          return response.lethal;
        default:
          return response;
      }
    }
  },
  watch: {
    currentFilter() {
      this.populate();
    }
  }
};
</script>
