<template>
  <div>
    <apexchart
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import Service from "../../services";
export default {
  props: {
    currentFilter: {
      type: String
    },
    city: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      data: [],
      series: [],
      chartOption: {}
    };
  },
  created() {
    this.populate();
  },
  methods: {
    getArrayForTypeFilter() {
      let series = [];
      switch (this.currentFilter) {
        case "confirmed":
          series.push({ name: "Confirmado", data: [] });
          break;
        case "suspicious":
          series.push({ name: "Suspeiros", data: [] });
          break;
        case "discarded":
          series.push({ name: "Descartados", data: [] });
          break;
        case "recovered":
          series.push({ name: "Curados", data: [] });
          break;
        case "lethal":
          series.push({ name: "Óbitos", data: [] });
          break;

        default:
          series.push(
            {
              name: "Confirmado",
              data: []
            },
            { name: "Suspeitos", data: [] },
            { name: "Descartados", data: [] },
            { name: "Curados", data: [] },
            {
              name: "Óbitos",
              data: []
            }
          );
          break;
      }
      return series;
    },
    populate() {
      let colors = [];
      switch (this.currentFilter) {
        case "confirmed":
          colors = ["#EA3546"];
          break;
        case "suspicious":
          colors = ["#F86624"];
          break;
        case "discarded":
          colors = ["#999999"];
          break;
        case "recovered":
          colors = ["#08BB68"];
          break;
        case "lethal":
          colors = ["#000"];
          break;
        default:
          colors = ["#EA3546", "#F86624", "#999999", "#08BB68", "#000"];
          break;
      }
      const request = new Service();
      request.get(`statistics/gender/${this.city}`).then(res => {
        let series = this.getArrayForTypeFilter();

        let response = this.getDataForTypeFilter(res);
        if (response == undefined)
          this.series = [
            {
              ...series[0],
              data: [response?.qty_male || 0, response?.qty_female || 0]
            }
          ];
        else {
          if (this.currentFilter == "all") {
            this.series = [
              {
                ...series[0],
                data: [
                  parseInt(response.confirmed?.qty_male || 0) +
                    parseInt(response.recovered?.qty_male || 0) +
                    parseInt(response.lethal?.qty_male || 0),
                  parseInt(response.confirmed?.qty_female || 0) +
                    parseInt(response.lethal?.qty_female || 0) +
                    parseInt(response.recovered?.qty_female || 0)
                ]
              },
              {
                ...series[1],
                data: [
                  response.suspicious?.qty_male || 0,
                  response.suspicious?.qty_female || 0
                ]
              },
              {
                ...series[2],
                data: [
                  response.discarded?.qty_male || 0,
                  response.discarded?.qty_female || 0
                ]
              },
              {
                ...series[3],
                data: [
                  response.recovered?.qty_male || 0,
                  response.recovered?.qty_female || 0
                ]
              },
              {
                ...series[4],
                data: [
                  response.lethal?.qty_male || 0,
                  response.lethal?.qty_female || 0
                ]
              }
            ];
          } else {
            if (this.currentFilter == "confirmed") {
              this.series = [
                {
                  ...series[0],
                  data: [
                    parseInt(res.confirmed?.qty_male || 0) +
                      parseInt(res.recovered?.qty_male || 0) +
                      parseInt(res.lethal?.qty_male || 0),
                    parseInt(res.confirmed?.qty_female || 0) +
                      parseInt(res.lethal?.qty_female || 0) +
                      parseInt(res.recovered?.qty_female || 0)
                  ]
                }
              ];
            } else {
              this.series = [
                {
                  ...series[0],
                  data: [response.qty_male || 0, response.qty_female || 0]
                }
              ];
            }
          }
        }
      });
      this.chartOptions = {
        chart: {
          type: "bar",
          stacked: true,
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            }
          },
          zoom: {
            enabled: true
          }
        },
        colors: colors,
        title: {
          text: "Distribuição de casos por sexo",
          align: "left",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            color: "#263238"
          }
        },

        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        legend: {
          show: true,
          position: "top"
        },
        xaxis: {
          categories: ["Homem", "Mulher"]
        }
      };
    },
    getDataForTypeFilter(response) {
      switch (this.currentFilter) {
        case "confirmed":
          return response.confirmed;
        case "suspicious":
          return response.suspicious;
        case "discarded":
          return response.discarded;
        case "recovered":
          return response.recovered;
        case "lethal":
          return response.lethal;
        default:
          return response;
      }
    }
  },
  watch: {
    currentFilter: function() {
      this.populate();
    }
  }
};
</script>
